import { render, staticRenderFns } from "./IconLocations.vue?vue&type=template&id=d24bd46a&scoped=true&"
import script from "./IconLocations.vue?vue&type=script&lang=js&"
export * from "./IconLocations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d24bd46a",
  null
  
)

export default component.exports