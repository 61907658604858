<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 40"
    style="enable-background:new 0 0 30 40;"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M15,6.6c-7.4,0-13.4,6-13.4,13.4s6,13.4,13.4,13.4s13.4-6,13.4-13.4S22.4,6.6,15,6.6z M21.3,25.3
	c-0.1,0.1-2.1,2.5-5.9,2.5c-4.6,0-7.8-3.4-7.8-7.9c0-4.4,3.3-7.7,7.8-7.7c3.6,0,5.5,2,5.6,2.1c0.2,0.2,0.2,0.5,0.1,0.8L19.8,17
	c-0.2,0.3-0.7,0.4-1,0.1c0,0-1.4-1.3-3.3-1.3c-2.5,0-4,1.8-4,4.1c0,2.1,1.4,4.3,4,4.3c2.1,0,3.5-1.5,3.5-1.5c0.3-0.3,0.8-0.3,1,0.1
	l1.3,1.8C21.5,24.8,21.5,25.1,21.3,25.3L21.3,25.3z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
