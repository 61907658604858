<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 40"
    style="enable-background:new 0 0 30 40;"
    xml:space="preserve"
  >
    <path
      id="markericon"
      class="st0"
      d="M13.8,34.9C5,22.1,3.4,20.8,3.4,16.1C3.4,9.7,8.6,4.5,15,4.5s11.6,5.2,11.6,11.6
	c0,4.7-1.6,6-10.4,18.8c-0.5,0.7-1.4,0.8-2,0.4C14,35.2,13.9,35,13.8,34.9z M15,21c2.7,0,4.8-2.2,4.9-4.8s-2.2-4.8-4.8-4.9
	s-4.8,2.2-4.9,4.8C10.2,18.8,12.3,21,15,21C15,21,15,21,15,21z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
