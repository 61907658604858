<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 40"
    style="enable-background:new 0 0 30 40;"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M24.2,29.6c0-0.5-0.5-0.7-0.9-0.5c-0.5,0-1.7,0.2-2.6,0.2c-3.6,0-6.2-2.1-7.4-5.2h6.9c0.2,0,0.7-0.2,0.7-0.5
	l0.5-1.7c0-0.5-0.2-0.9-0.7-0.9h-8.3c0-0.9,0-1.7,0-2.6h9c0.2,0,0.7-0.2,0.7-0.5l0.5-1.9c0-0.5-0.2-0.9-0.7-0.9h-8.5
	c1.2-2.8,3.8-4.5,7.1-4.5c0.9,0,1.7,0.2,2.4,0.2c0.5,0,0.7-0.2,0.9-0.5l0.7-2.6c0-0.5-0.2-0.7-0.5-0.9c-0.9-0.2-2.1-0.5-3.6-0.5
	c-5.9,0-10.7,3.6-12.3,8.8H5.9c-0.2,0-0.7,0.2-0.7,0.7v1.9c0,0.5,0.2,0.7,0.7,0.7h1.4c0,0.7,0,1.9,0,2.6H5.9c-0.5,0-0.7,0.2-0.7,0.7
	v1.7c0,0.5,0.2,0.7,0.7,0.7h1.9c1.4,5.5,6.2,9.5,12.6,9.5c1.7,0,2.8-0.2,3.8-0.5c0.5,0,0.7-0.5,0.5-0.9L24.2,29.6z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
